<template>
  <div id="rbsContainer">
    <Loader v-if="getIsLoading" />
    <div v-else style="background:rgba(255,255,255,0.5);">
      <v-card class="rbs-vcard" tile elevation="0">
        <v-toolbar-title
          style="margin:0; width:60%;display:flex; justify-content:center; align-items:center;"
          ><p style="margin:0; margin-right:8px;">Ruang</p>
          <p style="font-weight:bold; margin:0;font-size:20px; ">
            {{ room(this.$route.params.room_id) }}
          </p>
        </v-toolbar-title>

        <v-toolbar-title
          style="margin:0; font-size:18px; width:40%;display:flex; justify-content:center; align-items:center; border-left:1px solid #e0e0e0;"
        >
          {{ new Date() | moment('DD MMMM YYYY') }}
        </v-toolbar-title>
      </v-card>
      <p style="margin:0; margin-left:30px; font-size: 14px;">
        <span style="color:yellow; background:grey;">WARNING!</span>
        Maximal peserta untuk penggunaan ruang ini adalah
        <span style="color:red; text-decoration:underline;">
          {{ roomCapacity(this.$route.params.room_id) }}
        </span>
        orang
      </p>
      <div class="container-fluid">
        <div class="rbs-fluid-div1" v-if="data !== null">
          <v-row style="padding:10px;">
            <v-col
              v-for="(item, index) in data.data"
              :key="index"
              cols="12"
              sm="12"
              md="12"
            >
              <v-card class="mx-auto" width="100%" color="white">
                <div>
                  <div style="position:relative; z-index:2;overflow:auto;">
                    <v-toolbar rounded class="rbs-title" elevation="1">
                      <v-toolbar-title style="margin:auto;">
                        {{ item.meeting_type }} Meeting</v-toolbar-title
                      >
                    </v-toolbar>
                    <div style="padding:5px 0;">
                      <v-card-subtitle
                        style="padding:5px 15px; display:flex; flex-direction:row;min-width:280px;"
                      >
                        <p style="font-weight:bold; min-width:110px; margin:0;">
                          Waktu Pakai
                        </p>
                        <p style="margin:0;width:5px; margin-right:5px;">
                          :
                        </p>
                        <p style="margin:0;width:300px;">
                          {{
                            new Date(item.start_use).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit'
                            }) +
                              ' - ' +
                              new Date(item.end_use).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                              })
                          }}
                        </p>
                      </v-card-subtitle>

                      <v-card-subtitle
                        style="padding:5px 15px; display:flex; flex-direction:row;"
                      >
                        <p style="font-weight:bold; min-width:110px; margin:0;">
                          Jumlah Peserta
                        </p>
                        <p style="margin:0;width:5px; margin-right:5px;">
                          :
                        </p>
                        <p style="margin:0;width:300px;">
                          {{ item.number_of_attendance }}
                        </p>
                      </v-card-subtitle>

                      <v-card-subtitle
                        style="padding:5px 15px; display:flex; flex-direction:row;"
                      >
                        <p style="font-weight:bold; min-width:110px; margin:0;">
                          Perusahaan
                        </p>
                        <p style="margin:0;width:5px; margin-right:5px;">
                          :
                        </p>
                        <p style="margin:0;width:300px;">
                          {{ item.company_name }}
                        </p>
                      </v-card-subtitle>

                      <v-card-subtitle
                        style="padding:5px 15px; display:flex; flex-direction:row;"
                      >
                        <p style="font-weight:bold; min-width:110px; margin:0;">
                          Department
                        </p>
                        <p style="margin:0;width:5px; margin-right:5px;">
                          :
                        </p>
                        <p style="margin:0;width:300px;">
                          {{ item.department_name }}
                        </p>
                      </v-card-subtitle>

                      <v-card-subtitle
                        style="padding:5px 15px; display:flex; flex-direction:row;"
                      >
                        <p style="font-weight:bold; min-width:110px; margin:0;">
                          PIC
                        </p>
                        <p style="margin:0;width:5px; margin-right:5px;">
                          :
                        </p>
                        <p style="margin:0;width:300px;">
                          {{ item.employee_name }}
                        </p>
                      </v-card-subtitle>
                      <v-card-subtitle
                        style="padding:5px 15px; display:flex; flex-direction:row;"
                      >
                        <p style="font-weight:bold; min-width:110px; margin:0;">
                          Keterangan
                        </p>
                        <p style="margin:0;width:5px; margin-right:5px;">
                          :
                        </p>
                        <p style="margin:0;width:300px;">
                          {{ item.description }}
                        </p>
                      </v-card-subtitle>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div
          style="height:85vh;display:flex; justify-content:center; align-items:center;"
          v-else
        >
          <v-card
            class=" mx-auto"
            style="border:1px solid #e0e0e0; width:100%;min-height:50vh;display:flex; justify-content:center; align-items:center; "
          >
            <v-toolbar-title style="font-size:14px; font-weight:bold;">
              SCHEDULE NOT FOUND
            </v-toolbar-title>
          </v-card>
        </div>
        <router-link to="/meeting-activity/booking-form">
          <v-btn
            cols="12"
            color="black"
            elevation="2"
            dark
            small
            style="position:absolute; bottom:10px; right:10px;font-weight:bold; color:cyan;"
          >
            Make Request
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '../../../components/common/loader/CircularProgress'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    data: null,
    show: false
  }),
  components: { Loader },
  created() {
    this.setIsLoading(true)
    this.verifyRoomId(this.$route.params.room_id)
  },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapActions(['checkRoomSchedule']),
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            timer: 10000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    verifyRoomId(id) {
      this.checkRoomSchedule(id)
        .then(res => {
          setTimeout(() => {
            console.log(res)
            if (res.status_code !== '-99') {
              this.data = res
            } else {
            }
            this.setIsLoading(false)
          }, 2000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, please contact an Admin'
                : 'Something went wrong, please contact an Admin'
            )
            this.setIsLoading(false)
          }, 2000)
        })
    },
    room(id) {
      switch (id) {
        case '1':
          return 'Merak 1'
        case '2':
          return 'Merak 2'

        case '3':
          return 'Merak 3'

        case '4':
          return 'Elang'

        case '5':
          return 'Cenderawasih'

        case '6':
          return 'Rajawali'

        default:
          return '-'
      }
    },
    roomCapacity(id) {
      console.log(id)
      switch (Number(id)) {
        case 1:
          return '9'
        case 2:
          return '6'
        case 3:
          return '5'
        case 4:
          return '7'
        case 5:
          return '7'
      }
    }
  }
}
</script>

<style lang="scss" src="">
#rbsContainer {
  background: white;
  width: 100%;
  position: fixed;
  z-index: 2;
  height: 100vh;
  background-image: url('../../../assets/image/e-sanqua-logo-1.jpg'),
    url('../../../assets/image/background1.png');
  background-size: 250px 350px, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center 120px, center;
  .rbs-vcard {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    border: 1px solid #e0e0e0;
  }
  .container-fluid {
    padding: 10px;
    height: 90vh;
    width: 99.9%;
    .rbs-fluid-div1 {
      margin-top: 10px;
      height: 100%;
      padding: 10px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .rbs-title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
</style>
